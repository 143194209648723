import CommingSoon from "./components/pages/ComingSoon/CommingSoon";


function App() {
  return (
    <div className="App">
      <CommingSoon />
    </div>
  );
}

export default App;
