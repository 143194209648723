import astronaut from '../../atoms/img/astronaut.png';
import './ComingSoon.scss'

function CommingSoon() {
  return (
    <div className='coming-soon'>
      <img src={astronaut} className="astronaut" alt="astronaut" />
      <p className='coming-soon__text'>
          Hi,
          <span className='primary-color'>something awesome</span>
          is coming.
      </p>
    </div>
  )
}

export default CommingSoon;